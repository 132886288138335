export const serverApi: string = `${process.env.REACT_APP_API_URL}`;

export const Messages = {
  error1: "Something went wrong!",
  error2: "Please login first!",
  error3: "Please fulfill all inputs!",
  error4: "Message is empty!",
  error5: "Only images with jpeg,jpg and png format allowed!",
  error6: "Passwords differ please input the same password!",
};
