export const plans = [
  {
    title: "Apple vision",
    videoUrl: "https://www.youtube.com/watch?v=G4r4pD8l_Gw",
  },
  {
    title: "Iphone 15",
    videoUrl: "https://www.youtube.com/watch?v=0X0Jm8QValY",
  },
  {
    title: "IPAD PRO",
    videoUrl: "https://www.youtube.com/watch?v=eY9dXcjkVx8",
  },
  {
    title: "Airpods 2",
    videoUrl: "https://www.youtube.com/watch?v=f3DfJxvkN-8",
  },
  {
    title: "Apple watch ultra",
    videoUrl: "https://www.youtube.com/watch?v=HjEqOWjTkHE",
  },
];
